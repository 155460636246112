.create-order-section {
  padding: 20px;
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin: 0 5px;
}

.step.active {
  background: linear-gradient(135deg, #3399ff, #66ccff);
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.order-form {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.file-upload-container {
  margin-top: 20px;
}

.file-upload-area {
  width: 80%;
  margin: 0 auto;
  border: 2px dashed #3399ff;
  padding: 40px;
  text-align: center;
  border-radius: 5px;
  background: linear-gradient(135deg, #e0f7fa, #80deea);
  cursor: pointer;
  transition: background 0.3s ease;
}

.file-upload-area p {
  margin: 0;
  font-size: 16px;
}

.file-upload-area:hover {
  background: linear-gradient(135deg, #b2ebf2, #4dd0e1);
}

.file-upload-choose {
  color: #3399ff;
  text-decoration: underline;
  cursor: pointer;
}

.file-input {
  display: none;
}

.file-list {
  margin-top: 10px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.remove-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button:hover {
  background-color: darkred;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-navigation button {
  padding: 10px 20px;
  border: none;
  background-color: #3399ff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.form-navigation button:hover {
  background-color: #0073e6;
}

.confirm-payment {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.confirm-payment-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #3399ff, #66ccff);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.paypal {
  background-color: rgb(15, 11, 11);

  margin-top: 10px;
  padding: 10px;
  border-radius: 3px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 20px;
}

.confirm-yes,
.confirm-no {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.confirm-yes {
  background: linear-gradient(135deg, #4caf50, #81c784);
  color: white;
}

.confirm-no {
  background: linear-gradient(135deg, #f44336, #e57373);
  color: white;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-navigation button {
  padding: 10px 20px;
  border: none;
  background-color: #3399ff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.form-navigation button:hover {
  background-color: #0073e6;
}

.pay-later {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pay-later button {
  padding: 10px 20px;
  border: none;
  background-color: #3399ff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .confirm-payment-container {
    width: 100%;
  }
}
