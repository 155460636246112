.single-blog-section {
  width: 100%;
  min-height: 100vh;
}

.single-blog-section-inner {
  width: 90%;
  margin: 0 auto;
}

.single-blog-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.single-blog-part-image {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  cursor: pointer;
}

.sing-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.single-blog-p-tags {
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.single-blog-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.single-blog-description {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 60px;
}

.single-blog-btn {
  margin-bottom: 20px;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
