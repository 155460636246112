.table-container {
  width: 100%;
  overflow-x: auto;
  padding: 20px;
  background-color: #f7f7f7;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  cursor: pointer;
}

.responsive-table thead {
  background-color: #007bff;
  color: white;
}

.responsive-table th,
.responsive-table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.responsive-table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.responsive-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table-link {
  display: contents;
  text-decoration: none;
  color: inherit;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.pagination-icon {
  cursor: pointer;
  font-size: 20px;
  color: #007bff;
  margin: 0 10px;
}

.pagination-number {
  font-size: 18px;
  font-weight: bold;
}

.disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 568px) {
  .table-container {
    padding: 10px;
  }

  .responsive-table th,
  .responsive-table td {
    padding: 10px;
    font-size: 14px;
  }

  .sidebar-render.open {
    z-index: 900;
  }

  .available-jobs {
    justify-content: flex-start;
  }
}
