.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-content h2 {
  margin-top: 0;
  text-align: center;
}

.modal-disabled-texts {
  background: red;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid #c3e6cb;
  margin-top: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.modal-content input {
  margin-top: 5px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.modal-content button {
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.modal-content button[type="button"] {
  background: #6c757d;
}

.modal-content button:hover {
  background: #0056b3;
}

.modal-content button[type="button"]:hover {
  background: #5a6268;
}

.modal-alternatives {
  margin-top: 20px;
  text-align: center;
}

.modal-alternatives ol {
  padding-left: 20px;
  text-align: left;
}

.modal-alternatives li {
  margin-bottom: 5px;
}

@media (max-width: 568px) {
  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 1.5em;
  }

  .modal-content input {
    font-size: 14px;
    padding: 8px;
  }

  .modal-content button {
    font-size: 14px;
    padding: 8px;
  }
}
