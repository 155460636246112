.main-dashboard {
  width: 100%;
  margin-top: 30px;
  min-height: 90vh;
  /* background-color: #f7f7f7; */
}

.main-dashboard-container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 255, 0.2),
    0 6px 20px 0 rgba(0, 0, 255, 0.19);
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.flex-item {
  display: flex;
  /* background-color: #007bff; */
  background: linear-gradient(135deg, #3399ff, #66ccff);

  color: white;
  font-weight: bold;
  margin: 1rem;
  min-width: 300px;
  min-height: 150px;
  box-sizing: border-box;
  cursor: pointer;
  /* box-shadow: 0px 2px 4px rgba(86, 128, 233, 0.5); */
  box-shadow: 0px 2px 4px rgba(136, 96, 208, 0.5);
  border-radius: 0.25rem;
}

.flex-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  width: 100%;
}

.flex-description {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-icon {
  font-size: 40px;
  font-weight: bold;
}

.flex-count-p {
  font-size: 24px;
  font-weight: bold;
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .flex-item {
    min-width: 250px;
  }
  .flex-container {
    margin-left: 20px;
    justify-content: flex-start;
  }

  .subjects {
    min-height: 12vh;
  }
}

@media screen and (max-width: 568px) {
  .flex-item {
    min-width: 285px;
    /* justify-content:center; */
    /* align-items: center; */
    /* margin-left:auto; */
    /* margin-right: auto; */
  }
}
