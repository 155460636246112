/* starRating.css */
.star-rating {
  display: flex;
  align-items: center;
}

.star {
  font-size: 24px;
  color: #ccc;
  transition: color 0.3s;
  cursor: pointer;
}

.star.filled {
  color: gold;
}
