.print {
  margin-bottom: 10px;
  font-size: 21px;
  cursor: pointer;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.print-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.print-icon {
  cursor: pointer;
  margin-left: 10px;
  font-size: 24px;
}
