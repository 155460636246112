.blogs-section {
  width: 100%;
  min-height: 100vh;
}

.blog-section-inner {
  width: 90%;
  margin: 0 auto;
}

.blog-image-display {
  width: 100%;
  height: 300px;
  position: relative;
  margin-top: 10px;
}

.blogImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-title-div {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  top: 40%;
  left: 30%;
}

.blog-title-div-p {
  font-weight: bold;
  font-size: 53px;
  text-align: center;
  margin-top: 20px;
  color: white;
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.blog-section-main {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blog-image {
  flex: 1;
}

.blog-image-class {
  width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.blog-content {
  flex: 2;
  padding: 0 10px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.blog-description {
  font-size: 14px;
  color: #555;
  word-break: break-word;
  margin-top: 10px;
}

.create-blog-button {
  width: 200px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-top: 20px;
}

.create-blog-button:hover {
  background-color: #0056b3;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.no-blogs {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}

@media screen and (max-width: 568px) {
  .blogs-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-section-main {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .blog-image,
  .blog-content {
    width: 90%;
  }

  .blog-content {
    padding: 10px;
  }
  .blog-title {
    text-align: center;
  }
  .blog-description {
    text-align: center;
  }

  .blog-title-div {
    top: 40%;
    left: 10%;
  }

  .blog-title-div-p {
    font-size: 33px;
  }
}

@media only screen and (min-width: 568px) and (max-width: 912px) {
  .blogs-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-section-main {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .blog-image,
  .blog-content {
    width: 100%;
  }

  .blog-content {
    padding: 10px;
  }

  .blog-title-div {
    top: 40%;
    left: 10%;
  }
}

@media only screen and (min-width: 912px) and (max-width: 1024px) {
  .blogs-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-section-main {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .blog-image,
  .blog-content {
    width: 100%;
  }

  .blog-content {
    padding: 10px;
  }
}
