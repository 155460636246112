.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.9); */
  /* background-color: black; */
  background: linear-gradient(to bottom, rgb(47, 29, 120), rgb(68, 29, 29));

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinning-icon {
  font-size: 50px;
  animation: spin 2s linear infinite;
  color: #007bff;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
