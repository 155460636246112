.gig-details {
  width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 50px;
}

.bids {
  width: 60%;
  background: linear-gradient(135deg, #3399ff, #66ccff);
  color: white;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.job-name p,
.files p {
  font-size: 20px;
  font-weight: 600;
}

.job-description,
.comments {
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.overflow-value {
  overflow-x: auto;
  overflow-y: auto;
}

.bids-section {
  margin-top: 20px;
}

.bid {
  background: white;
  color: black;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.assign-button {
  background: #3399ff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.assign-button:hover {
  background: #287ab8;
}

.details {
  width: 40%;
  background: linear-gradient(135deg, #3399ff, #66ccff);
  color: white;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.specifics {
  background: linear-gradient(135deg, #3399ff, #66ccff);
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.specifics-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gig-specs {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid white;
}

.gig-specs-name {
  font-weight: bold;
}

@media screen and (max-width: 568px) {
  .gig-details {
    flex-direction: column;
    margin-top: 20px;
  }

  .bids {
    width: 95%;
  }
  .details {
    width: 100%;
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .gig-details {
    flex-direction: column;
    margin-top: 20px;
  }

  .bids,
  .details {
    width: 100%;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .gig-details {
    flex-direction: column;
    margin-top: 20px;
  }

  .bids,
  .details {
    width: 100%;
  }
}
