/* Styles for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Styles for the modal content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  box-sizing: border-box;
}

.modal-content h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 600;
}

/* Styles for the form */
.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  font-size: 18px;
  margin-bottom: 10px;
}

.modal-content .stars {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
}

.modal-content .star {
  font-size: 2rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.modal-content .star.selected {
  color: gold;
}

.modal-content .star:hover {
  color: gold;
}

/* Styles for the buttons container */
.modal-content .buttons {
  display: flex;
  justify-content: space-between;
}

.modal-content button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-content button[type="submit"] {
  background-color: #28a745;
  color: white;
}

.modal-content button[type="submit"]:hover {
  background-color: #218838;
}

.modal-content button[type="button"] {
  background-color: #dc3545;
  color: white;
}

.modal-content button[type="button"]:hover {
  background-color: #c82333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .modal-content {
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 20px;
  }

  .modal-content label {
    font-size: 16px;
  }

  .modal-content .star {
    font-size: 1.5rem;
  }

  .modal-content button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
