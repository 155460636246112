.nav-main {
  width: 100%;
  height: 2cm;
  background-color: white;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 255, 0.2),
    0 6px 20px 0 rgba(0, 0, 255, 0.19);
}

.nav-content {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-nav {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.left-nav-image {
  width: 15%;
}
.left-nav-image-Logo {
  /* width: 70px; */
  /* object-fit: cover; */
  cursor: pointer;

  width: 50px;
  height: 50px;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
}

/* .left-nav-image-Logo:hover{
    transform: scale(1.1);
} */

.p-title {
  font-size: 20px;
  font-weight: bold;
  color: rgb(28, 31, 90);
  text-shadow: 1px 1px 2px #3399ff, 2px 2px 4px #66ccff;
}

.middle-nav {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.btn-31,
.btn-31 *,
.btn-31 :after,
.btn-31 :before,
.btn-31:after,
.btn-31:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-31 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  border-radius: 4px;
}
.btn-31:disabled {
  cursor: default;
}
.btn-31:-moz-focusring {
  outline: auto;
}
.btn-31 svg {
  display: block;
  vertical-align: middle;
}
.btn-31 [hidden] {
  display: none;
}
.btn-31 {
  border-width: 1px;
  padding: 1rem 2rem;
  position: relative;
  text-transform: uppercase;
}
.btn-31:before {
  --progress: 100%;
  /* background: 
#fff; */
  /* background: linear-gradient(135deg, #3399ff, #66ccff); */
  background-color: rgba(44, 44, 142, 0.637);
  -webkit-clip-path: polygon(
    100% 0,
    var(--progress) var(--progress),
    0 100%,
    100% 100%
  );
  clip-path: polygon(
    100% 0,
    var(--progress) var(--progress),
    0 100%,
    100% 100%
  );
  content: "";
  inset: 0;
  position: absolute;
  transition: -webkit-clip-path 0.2s ease;
  transition: clip-path 0.2s ease;
  transition: clip-path 0.2s ease, -webkit-clip-path 0.2s ease;
}
.btn-31:hover:before {
  --progress: 0%;
}
.btn-31 .text-container {
  display: block;
  overflow: hidden;
  position: relative;
}
.btn-31 .text {
  display: block;
  font-weight: 900;
  mix-blend-mode: difference;
  position: relative;
}
.btn-31:hover .text {
  -webkit-animation: move-up-alternate 0.3s ease forwards;
  animation: move-up-alternate 0.3s ease forwards;
}
@-webkit-keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes move-up-alternate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(80%);
  }
  51% {
    transform: translateY(-80%);
  }
  to {
    transform: translateY(0);
  }
}

.right-nav {
  width: 30%;

  margin-right: 20px;

  display: flex;
  flex-direction: column;
  margin-top: 120px;
  gap: 15px;
  align-items: center;
  min-height: 200px;
  position: relative;
}

.right-nav-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-tag {
  font-weight: bold;
  font-size: 19px;
}

.person-icon {
  width: 50px;
  object-fit: cover;
  background-repeat: no-repeat;
  height: auto;
}

.user-name {
  font-weight: 600;
}

.profile-dropdown {
  margin-top: 5px;
}

.right-nav-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: #5680e9;
  padding: 13px;
  cursor: pointer;
  border-radius: 2px;
  color: white;
  box-shadow: 0px 2px 4px rgba(86, 128, 233, 0.5);
  position: absolute;
  top: 65px;
}

.profile {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.profile-dropdown-icon {
  font-size: 18px;
}

@media screen and (max-width: 568px) {
  .nav-main {
    height: 2.5cm;
  }
  .nav-content {
    width: 100%;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }
  .left-nav {
    width: 35%;

    flex-direction: column;
    justify-content: center;
    /* align-items:center; */
  }
  .left-nav-image {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .p-title {
    font-size: 15px;
    text-align: center;
    margin-bottom: 5px;
  }
  .left-nav-image-Logo {
    width: 60px;
    margin-top: 10px;
  }
  .right-nav {
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 10px;
    justify-content: center;
  }
  .right-nav-main {
    flex-direction: column;
    gap: 2px;
  }
  .profile-tag {
    font-size: 17px;
    text-align: center;
  }
  .middle-nav {
    width: 35%;
    margin-left: 10px;
  }

  .btn-31 {
    padding: 4px;
  }

  .right-nav-dropdown {
    top: 155px;
  }
}
